import { SessionHTMLElement } from "@components/web/Session";

export class LoggedInAvatar extends SessionHTMLElement {
  private _root: ShadowRoot;

  constructor() {
    super();
    this._root = this.attachShadow({ mode: "open" });
  }

  async render() {
    this._root.innerHTML = `
        <span part="avatar">
          ${this._sessionState?.user?.name?.charAt(0) ?? "?"}
        </span>
        <span part="name">
          ${this._sessionState?.user?.name ?? "Ukjent bruker"}
        </span>
      `;
  }
}
