import { loginClient } from "@components/solid/AuthContext";
import type { LoginSession } from "@nrk/innlogging-web/redirect-login-client";

/*
  Component for shared session rendering logic for web components (custom elements).
  TODO: Should this be a interface?
*/

export class SessionHTMLElement extends HTMLElement {
  protected _sessionState!: LoginSession;

  protected isLoggedIn() {
    return this._sessionState?.isLoggedIn ?? false;
  }

  constructor() {
    super();
  }

  async updateSessionState() {
    this._sessionState = await loginClient.getSession();
  }

  async render() {}

  async sessionConnectedCallback() {
    await this.updateSessionState();
    loginClient.addEventListener("update", this.render.bind(this));
  }

  async connectedCallback() {
    await this.sessionConnectedCallback();
  }

  disconnectedCallback() {
    loginClient.removeEventListener("update", this.render.bind(this));
  }
}
