import { loginClient } from "@components/solid/AuthContext";
import { SessionHTMLElement } from "@components/web/Session";

export class SessionSwitch extends SessionHTMLElement {
  private _root: ShadowRoot;

  constructor() {
    super();
    this._root = this.attachShadow({ mode: "open" });
  }

  async render() {
    this._root.innerHTML = `
        <style>
          :host {
            display: contents;
          }
          :host::part(active) {
            display: ${this.isLoggedIn() ? "block" : "none"};
          }
          :host::part(inactive) {
            display: ${this.isLoggedIn() ? "none" : "block"};
          }
          ::slotted(*[name="active"]) {
            display: ${this.isLoggedIn() ? "block" : "none"};
          }
          ::slotted(*[name="inactive"]) {
            display: ${this.isLoggedIn() ? "none" : "block"};
          }
        </style>
        <slot part="active" name="active">
          <button data-session-switch>Logg av</button>
        </slot>
        <slot part="inactive" name="inactive">
          <button data-session-switch>Logg på</button>
        </slot>
      `;
  }

  async handleActiveClick(evt: Event): Promise<void> {
    const target = evt.target as HTMLButtonElement;
    if (target.hasAttribute("data-session-switch")) {
      loginClient.logout();
    }
  }

  async handleInactiveClick(evt: Event): Promise<void> {
    const target = evt.target as HTMLButtonElement;
    if (target.hasAttribute("data-session-switch")) {
      loginClient.login();
    }
  }

  async connectedCallback() {
    await this.sessionConnectedCallback();
    this._root
      .querySelector('[part="active"]')
      ?.addEventListener(
        "click",
        this.handleActiveClick.bind(this) as EventListener,
      );
    this._root
      .querySelector('[part="inactive"]')
      ?.addEventListener(
        "click",
        this.handleInactiveClick.bind(this) as EventListener,
      );
  }
}
